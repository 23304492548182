.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bodySection {
  background-color: #F7F0E9;
}

.contentWrapper {
  margin: auto;
  background-color: #FFF;
}

@media screen and (min-width: 769px) {
  .bodySection {
    padding: 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .headerSection {
    padding: 70px 30px;
    max-width: 1440px;
    margin: auto;
  }

  .contentWrapper {
    margin-top: 50px;
    padding: 30px 50px;
    max-width: 1000px;
    box-shadow: 12px 12px 43px rgb(0, 0, 0, .1);
  }
}

// TODO: Replace with common breakpoint
@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 200px;
  }
  
  .bodySection {
    background: transparent;
  }

  .headerSection {
    &.step1 {
      margin: 20px;
    }

    &.step2 {
      display: none;
    }
  }

  .contentWrapper {
    padding: 20px;
    z-index: -3;
    background: transparent;
  }
}