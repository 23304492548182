
.textBlock {
  margin: 20px 0px;
}

.costPerDay {
  margin-top: 50px;

  h2 {
    color: #0C4261;
    margin: 0px;
  }
}

.perDayWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  h2 {
    margin: 0px;
  }

  h6 {
    font-size: 1.65rem;
    color: #0C4261;
  }
}

.how {
  margin-top: 30px;
}

.inlineWrapper {
  display: inline-block;
  padding-left: 5px;
  margin-top: -10px;
}

.day {
  display: inline-block;
}

.startAgain {
  button {
    font-size: 20px;
  }
}

@media screen and (min-width: 640px) {
  .costPerDay {
    display: flex;
    flex-direction: row;

    h2 {
      display: inline;
    }

    > h2 {
      margin-right: 10px;
    }
  }
}