
html, body, #root, .app {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}


// TODO: Replace with common breakpoint
@media screen and (max-width: 768px) {
 html, body, #root, .app {
    max-width: 100vw;
  }
}