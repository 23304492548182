// @use '~@sensible/components/theme.module.scss';

.container {
  height: 100%;
  width: 100%;
}


@media screen and (min-width: 769px) {
  .navWrapper > div > div {
      max-width: 1440px;
      width: 100%;
      margin: auto;
  }
}

